.root {
  position: relative;
  padding: 70px 0;
  background-color: var(--secondary-background-color);
}

.root .leftSwirt {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

.root button {
  max-width: 224px;
}

.title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: -0.02em;
}

.blogPostWrapper {
  margin-bottom: 15px;
  margin-top: 15px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .root .leftSwirt {
    display: none;
  }
}

@media (max-width: 768px) {
  .buttonWrapper {
    margin-top: 50px;
    margin-bottom: 0;
  }
}
