.root {
  position: relative;
  padding: 90px 0;
  background-color: var(--secondary-background-color);
}

.root .leftSwirt {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 80px;
}

.shortFaqWrapper {
  order: 2;
}

.shortFaqTitle {
  max-width: 80%;
  text-align: center;
  margin: 0 auto 15px;
}

.shortFaqSubtitle {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 25px;
  color: var(--primary-color);
  text-align: center;
}

.readAllFaqBtnWrapper {
  width: 100%;
  margin-top: 20px;
}

.contactWrapper {
  height: max-content;
  background-color: var(--white);
  padding: 50px;
  border-radius: 10px;
  order: 1;
}

.contactTitle {
  margin-bottom: 35px;
}

.contactText {
  margin-bottom: 40px;
}

.contactItem {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.contactItem:not(:last-of-type) {
  margin-bottom: 25px;
}

.contactItemLink {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  color: var(--black);
}

.shortFaqAccordion {
  border: none;
  border-radius: 10px !important;
  margin-bottom: 10px;
}

.shortFaqAccordionHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;

  background-color: var(--white);
  border-bottom: none;
  padding: 20px;
  min-height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.contactButtonsWrapper {
  display: grid;
  grid-gap: 20px;
  margin-top: 40px;
}

@media (max-width: 480px) {
  .contentWrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .contentWrapper > div:first-of-type {
    order: 1;
  }

  .shortFaqTitle {
    text-align: left;
    margin: 0 0 15px;
  }

  .shortFaqSubtitle {
    text-align: left;
  }

  .readAllFaqBtnWrapper {
    max-width: 236px;
  }
}

@media (min-width: 992px) {
  .contentWrapper {
    grid-template-columns: 1fr 450px;
    grid-gap: 120px;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .root .leftSwirt {
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .root .leftSwirt {
    display: none;
  }
}
