.modalWrapper {
  column-gap: 120px;
}

.modal > div {
  width: 90%;
  max-width: 1132px;
}

.modal > div > div {
  background: var(--white);
  border: 1px solid #ccc;
  box-shadow: 0px 5px 30px rgba(41, 42, 44, 0.15);
  border-radius: 20px;
  padding: 15px 35px;
}

.modal > div > div > div:first-child {
  border: none;
}

.modal :global(.modal-header) span {
  font-size: 30px;
  font-weight: 300;
  color: var(--primary-color);
}

.titleWrapper {
  margin-bottom: 50px;
}

.modalTitle {
  margin-bottom: 30px;
}

.modalSubtitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

.modalSubtitle a {
  color: var(--secondary-color);
}

.gridWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
}

@media (min-width: 768px) {
  .modal .titleWrapper {
    max-width: 70%;
  }

  .gridWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .gridWrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .modal > div {
    width: 70%;
    max-width: 1132px;
  }
}

@media (max-width: 515px) {
  .modalTitle {
    font-size: 8vw;
    line-height: 1.2;
  }
}
