.root,
.rootVertical {
  padding: 25px 30px;
  width: 100%;
  border: 1px solid var(--divider-color);
  border-radius: 10px;
  margin-bottom: 40px;
}

.rootImage {
  position: relative;
}

.rootImage .rightCircle {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-50%);
}

.root:not(:last-of-type) {
  margin-bottom: 30px;
}

.topLabelsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.id,
.status {
  margin-bottom: 0;
}

.id {
  color: #a5aab4;
}

.statusInfoWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.circleActive,
.circleLatest {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.circleActive {
  background-color: #1ad142;
}

.circleLatest {
  background-color: #ffa500;
}

.status {
  font-weight: 600;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 45px;
}

.title {
  margin-bottom: 25px;
}

.categoriesWrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 25px;
}

.category {
  margin-bottom: 0;
}

.dateWrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  margin-bottom: 25px;
}

.dateWrapper.alignCenter {
  align-items: center;
}

.dates {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 7px;
}

.rangeDate,
.startDate {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
}

.singleInformationWrapper:not(:last-of-type) {
  margin-bottom: 25px;
}

.informationLabel {
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
}

.informationTextWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 0;
}

.informationText {
  margin-bottom: 0;
}

.informationAdditionalText {
  font-size: 12px;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 0;
}

.singlePriceWrapper {
  margin-bottom: 7px;
}

.priceLabel {
  margin-bottom: 2px;
}

.priceValue {
  color: var(--black);
  margin-bottom: 0;
}

.singlePriceWrapperSale .priceLabel,
.isSalePrice.priceValue {
  color: #007a5a;
}

.singlePriceWrapperCoupon .priceValue {
  font-size: 16px;
  color: #e00d00;
}

.additionalPriceInfo {
  font-size: 12px;
  color: #5f6267;
  margin-bottom: 0;
}

.leftColumnContentWrapper {
  text-align: center;
}

.availableTitle {
  margin-bottom: 20px;
}

.calendarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 25px;
}

.calendarWrapper:hover {
  text-decoration: none;
}

.calendarText {
  color: var(--secondary-color);
  margin-bottom: 0;
}

.rootVertical .contentWrapper {
  grid-template-columns: repeat(1, 1fr);
}

.rootVertical .dateWrapper {
  margin-bottom: 0;
}

.rootVertical .singlePriceWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noteWrapper {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  border-top: 1px solid #dfe6ef;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 0;
}

.noteLabel {
  font-weight: 600;
}

.noteLabelColor {
  color: #e00d00;
}

.thirdColumn > button {
  margin-top: 30px;
}

.examTrainingPersonsInfo {
  text-align: right;
  color: #1ad142;
}

@media (min-width: 768px) {
  .contentWrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .leftColumnContentWrapper {
    position: sticky;
    top: 50px;
    text-align: left;
  }

  .calendarWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 8px;
    margin-bottom: 25px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .availableTitle {
    font-size: 3.3vw;
    line-height: 1.2;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .rootImage .rightCircle {
    display: none;
  }
}
