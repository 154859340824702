.title {
  margin-bottom: 30px;
}

.topContactDetails {
  margin-bottom: 40px;
}

.phoneNumber,
.mail {
  display: block;
  font-size: 32px;
  line-height: 46px;
  font-weight: 600;
  color: var(--black);
  text-decoration: none;
}

.hoursTitle {
  color: var(--black);
  margin-bottom: 15px;
}

.singleHourInfo {
  margin-bottom: 0;
}

.singleHourInfo:last-of-type {
  margin-bottom: 25px;
}

.socialsWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-bottom: 25px;
}

.contactFlex {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
