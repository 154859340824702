.headingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
}

.headingWrapper h6 {
  margin-bottom: 0;
}

.levelModalButton,
.levelModalButton:hover,
.levelModalButton:focus {
  padding: 0;
  width: max-content;
  height: max-content;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
