.answerTitle {
  margin-top: 5px;
  margin-bottom: 0;
}

.titleWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 0;
}

.levelModalButton,
.levelModalButton:hover,
.levelModalButton:focus {
  padding: 0;
  width: max-content;
  height: max-content;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}
