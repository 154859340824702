.root {
  position: relative;
  height: 100%;
  max-width: 357px;
  background-color: var(--white);
  border: 1px solid var(--divider-color);
  border-radius: 10px;
  padding: 20px;
  margin: 0 auto;
  overflow: hidden;
  display: grid;
}

.topCard,
.centerCard {
  border-bottom: 1px solid #dfe6ef;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.isActiveWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 20px;
}

.activeCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1ad142;
}

.activeTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--black);
  margin-bottom: 0;
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.forWhoWrapper {
  display: flex;
  align-items: center;
}

.forWhoWrapper > p {
  font-size: 14px;
  line-height: 1;
  color: var(--black);
  border-right: 1px solid #a5aab4;
  padding-right: 7px;
  margin-right: 7px;
  margin-bottom: 0;
}

.centerCard {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.costWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 10px;
}

.detailsWrapper > div:not(:last-of-type) {
  margin-bottom: 10px;
}

.centerCard .priceTitle {
  font-size: 14px;
  line-height: 22px;
  color: var(--black);
  margin-bottom: 0;
}

.centerCard .priceValue {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: var(--primary-color);
  margin-bottom: 0;
}

.centerCard .priceText {
  font-size: 12px;
  line-height: 20px;
  color: #007a5a;
  margin-bottom: 0;
}

.description {
  font-size: 12px;
  line-height: 20px;
  color: var(--black);
  margin-bottom: 20px;
}

.bottomCard {
  display: grid;
  align-items: flex-end;
}

.buttonsWrapper {
  display: grid;
  grid-gap: 10px;
  width: 100%;
}
