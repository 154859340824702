.root {
  margin-bottom: 50px;
}

.root h2 {
  letter-spacing: -0.02px;
}

.root img {
  max-width: 100%;
}

.root p > a {
  color: var(--secondary-color);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .root h2 {
    font-size: 4vw;
    line-height: 1.2;
    text-align: left;
  }

  .root h3 {
    font-size: 3.2vw;
    line-height: 1.2;
    text-align: left;
  }
}

@media (max-width: 515px) {
  .root h2 {
    font-size: 10vw;
    line-height: 1.2;
  }
}
