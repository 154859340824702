.root {
  position: relative;
}

.root .rightSwirt {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-20%);
}

@media (min-width: 1200px) {
  .firstColumn {
    padding-left: 0;
    padding-right: 30px;
  }

  .secondColumn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .thirdColumn {
    padding-left: 30px;
    padding-right: 0;
  }
}

@media (max-width: 515px) {
  .firstColumn section > div {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .container {
    padding-bottom: 0px;
  }

  .firstColumn,
  .secondColumn,
  .thirdColumn {
    padding-left: 15px;
    padding-right: 15px;
  }

  .secondColumn {
    order: 2;
  }

  .firstColumn section > div {
    text-align: left;
  }
}

@media (max-width: 1600px) {
  .thirdColumn {
    padding-right: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .root {
    overflow-x: hidden;
  }

  .root .rightSwirt {
    transform: translate(50%, -50%);
  }
}

@media (max-width: 767px) {
  .root .rightSwirt {
    display: none;
  }
}
