.contentWrapper,
.contentWrapperBorder {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  padding: 30px 0;
  margin: 0;
}

.contentWrapperBorder {
  border-bottom: 1px solid #dfe6ef;
}

.titleWrapper > img {
  position: relative;
  bottom: 7px;
  left: 7px;
}

.secondTitle,
.thirdTitle {
  margin-bottom: 20px;
}

.title {
  display: inline;
}

.singleItem {
  display: flex;
  align-items: flex-start;
  column-gap: 5px;
  margin-bottom: 10px;
}

.singleItem > img {
  position: relative;
  top: 3px;
}

.teacherRoot {
  text-align: center;
}

.teacherImage {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.teacherRoot h5:first-of-type {
  color: var(--primary-color);
}

.linkWrapper > button,
.linkWrapper > button:hover,
.linkWrapper > button:focus,
.linkWrapper > button:active {
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: var(--secondary-color);
  text-align: left;
  outline: none;
  box-shadow: none;
}

.linkWrapper > button > div {
  justify-content: flex-start;
}

@media (min-width: 992px) {
  .contentWrapper,
  .contentWrapperBorder {
    grid-template-columns: 4fr 5fr 5fr;
    grid-gap: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .contentWrapper {
    padding-bottom: 0;
  }
}

@media (min-width: 1366px) {
  .contentWrapper,
  .contentWrapperBorder {
    grid-gap: 50px;
  }
}
