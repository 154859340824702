.rootImage {
  position: relative;
}

.rootImage .rightSwirt {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 30px;
}

.serviceWrapper {
  padding: 30px 30px 65px;
  border: 1px solid var(--divider-color);
  border-radius: 10px;

  position: relative;
}

.serviceWrapper h5 {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  color: var(--primary-color);
  margin-bottom: 15px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 64px;
}

.serviceWrapper p:not(:last-of-type) {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--black);
  margin-bottom: 25px;
}

.iconWrapper {
  height: 205px;
  margin-bottom: 25px;
}

.icon {
  max-width: 100%;
  max-height: 100%;
}

.contentWrapper p {
  margin-bottom: 15px;
}

.contentWrapper p > a {
  position: absolute;
  bottom: 30px;
  left: 0;
  min-height: 36px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: var(--secondary-color);
  margin: 0 30px;
}

@media (max-width: 767px) {
  .icon {
    width: 100%;
  }

  .rootImage .rightSwirt {
    display: none;
  }
}
