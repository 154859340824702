.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 30px;

  width: 266.5px;
}

.filterCourses {
  width: 212px;
  height: 46px;
}

.filterCourses h3 {
  color: #000000;
  margin-bottom: 0;
}

.filterSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 767px) {
  .root {
    margin-top: 15px;
    text-align: initial;
  }
}

@media (min-width: 992px) {
  .root.isCoursesForYou {
    width: 100%;
    align-items: center;
    margin-bottom: 60px;
  }

  .root.isCoursesForYou .filterSectionWrapper {
    flex-direction: row;
  }
}
