.primary {
  font-size: 15px;
  font-weight: var(--button-weight);
  line-height: 18px;
  letter-spacing: 0.7px;
  color: var(--secondary-color);
  text-align: left;
  cursor: pointer;
}
.second {
  composes: primary;
  color: var(--secondary-color);
}
.linkStyleWrapper .second:hover {
  color: var(--primary-color);
}
.linkStyleWrapper .primary:hover {
  color: var(--secondary-color);
}
.iconStyle {
  margin: 3px 10px 3px 10px;
}

.linkStyleWrapper {
  display: flex;
  align-items: flex-start;
}

.centered {
  justify-content: center;
}
