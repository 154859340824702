.title {
  margin-bottom: 45px;
}

.linksWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-bottom: 30px;
}

.singleHourInfo {
  font-size: 12px;
  color: var(--black);
}

.singleHourInfo:last-of-type {
  margin-bottom: 0;
}
