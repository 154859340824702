.title {
  margin-bottom: 30px;
}

.logoWrapper {
  margin-bottom: 15px;
}

.logoWrapper img {
  height: 100px;
  width: auto;
}

.companyDataWrapper {
  display: grid;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.companyDataWrapper p {
  margin-bottom: 0;
}

.vatRegistration {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 20px;
}
