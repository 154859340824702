.root {
  padding: 100px 30px 0;
}

.root > div {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

.strengthsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--primary-color);
  margin-bottom: 30px;
  text-align: center;
}

.text {
  margin-bottom: 30px;
  text-align: center;
}

.button {
  max-width: 262px;
  width: 100%;
  height: 46px;
  background-color: var(--primary-color);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: var(--white);
  transition: 0.3s;
  margin: auto;
}

.button:hover {
  color: var(--white);
  background-color: var(--secondary-color);
  text-decoration: none;
}

.SingleStrength {
  margin-bottom: 30px;
}

.SingleStrength img {
  margin-bottom: 10px;
  max-width: 100%;
}

.SingleStrengthText {
  margin-bottom: 0;
}

.SingleStrength > a {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: var(--secondary-color);
  margin-top: 15px;
  transition: var(--animation);
  text-decoration: none;
}

.SingleStrength > a > img {
  transform: scale(0.9);
  margin: 0;
}

@media (min-width: 768px) {
  .root {
    padding: 100px 0;
  }

  .strengthsWrapper {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }

  .SingleStrength {
    min-height: 190px;
  }
}

@media (min-width: 992px) {
  .title {
    width: 80%;
    text-align: left;
  }

  .subtitle,
  .text {
    text-align: left;
  }

  .button {
    margin-left: 0;
  }

  .root > div {
    grid-template-columns: 1fr 2fr;
    grid-gap: 50px;
  }

  .strengthsWrapper {
    text-align: left;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
  }
}
