.root {
  position: relative;
  padding: 70px 0px;
}

.root .leftElipse {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.wrapperInverted {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.textWrapper {
  align-self: center;
}

.textWrapper h5 {
  font-size: 16px;
  line-height: 1.67;
  letter-spacing: 0.54px;
  text-align: left;
  color: var(--primary-color);
}

.textWrapper h6,
.textWrapper h5 {
  margin-top: 15px;
}

.article {
  margin-top: 5px;
  margin-bottom: 40px;
}

.mainImage {
  position: relative;
  width: 100%;
  max-width: 715px;
  height: 477px;
  object-fit: contain;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 15px;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0px;
  object-fit: contain;
}

.imagesWrapper {
  position: relative;
}

.imagesWrapper img {
  max-width: 100%;
  float: right;
}
.wrapperInverted .imagesWrapper img {
  float: left;
}

.none {
  display: none;
}

.middle {
  display: flex;
  align-items: center;
}
.margin {
  margin-top: 80px;
}

@media (max-width: 991px) {
  .textWrapper {
    width: 100% !important;
  }
  .wrapperInverted .imagesWrapper,
  .imagesWrapper {
    width: 100% !important;
    text-align: center;
  }
  .wrapperInverted .imagesWrapper img,
  .imagesWrapper img {
    float: none;
  }
  .mainImage {
    display: block;
    margin: 60px auto 0 auto;
  }
}

@media (max-width: 768px) {
  .root {
    padding-top: 40px;
  }
  .wrapper,
  .wrapperInverted {
    grid-gap: 30px;
  }
  .textWrapper,
  .textWrapper h5 {
    text-align: center;
  }
  .textWrapper h1 {
    font-size: 42px;
    line-height: 54px;
  }

  .mainImage {
    max-width: 344px;
    width: 100%;
    height: 100%;
    max-height: 350px;
    margin: 40px auto 0 auto;
  }
  .buttonWrapper {
    flex-direction: column;
    margin: 0px;
  }
  .buttonWrapper a {
    width: 100%;
  }
  .article > ul {
    list-style: none;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .root .leftElipse {
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .root .leftElipse {
    display: none;
  }
}
