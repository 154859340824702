.root {
  padding-bottom: 50px;
}

.featuresGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
}

.featuresGrid > div {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .featuresGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 993px) {
  .featuresGrid {
    grid-template-columns: repeat(3, 1fr);
  }
}
