.root {
  margin: 50px auto;
  width: 335px;
  padding: 0;
}
.root h4 {
  margin: 0;
}
.factsWrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 30px auto;
}
.richText {
  margin: 0 auto;
  width: 335px;
  margin-top: 40px;
  color: var(--paragraph-main-color);
}

.factCard {
  padding: 20px;
  width: 335px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
}

.factCard img {
  width: 60px;
  height: 60px;
  border: 1px solid var(--disabled-gray);
  padding: 15px;
  border-radius: 50%;
  margin-right: 20px;
}
.factCard p {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
