.ourTeamRoot {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgba(240, 243, 245, 0.3);
}

.title {
  text-align: center;
  margin-bottom: 50px;
}

.ourTeamWrapper {
  /* --grid-layout-gap: 30px;
  --grid-column-count: 6;
  --grid-item--min-width: 180px;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap); */
  column-gap: 30px;
  justify-content: center;
  text-align: center;
}
.ourTeamWrapper > div {
  flex: 1 1 1px;
}
.teamMemberName {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.teamMemberReadBio {
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  color: var(--black);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
}

.teamMemberReadBio:hover {
  text-decoration: none;
}
