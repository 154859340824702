.root {
  padding-top: 50px;
  padding-bottom: 50px;
}

.detailBoxLabel {
  margin-bottom: 40px;
}

.stickyWrapper {
  position: sticky;
  top: 20px;
}
