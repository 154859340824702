.eventWrapper {
  height: 100%;
  padding: 0 8px;
}

.root :global(.slick-slide) {
  height: inherit !important;
}

.root :global(.slick-slide) > div {
  height: 100%;
}

.root :global(.slick-track) {
  display: flex !important;
}

.root {
  overflow: hidden;
  margin-top: 70px;
}
.root .header {
  margin-bottom: 70px;
  text-align: center;
}

.root :global(.slick-next)::before,
.root :global(.slick-prev)::before {
  display: none;
}

.root :global(.slick-next),
.root :global(.slick-prev) {
  z-index: 1;
}

.root :global(.slick-arrow) {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--secondary-background-color);
}

@media (min-width: 992px) {
  .root :global(.slick-prev) {
    transform: translate(100%, -50%);
    left: 0;
  }

  .root :global(.slick-next) {
    transform: rotate(180deg) translate(100%, 50%) !important;
    right: 0;
  }

  .root :global(.slick-slide) {
    opacity: 0.5;
  }

  .root :global(.slick-slide.slick-active).visibleSlide,
  .root :global(.slick-slide.slick-active.slick-current),
  .root :global(.slick-slide.slick-active.slick-current) + div {
    opacity: 1 !important;
  }
}

@media (max-width: 515px) {
  .header {
    font-size: 10vw;
    line-height: 1.2;
  }
}

@media screen and (max-width: 991px) {
  .root {
    width: 90%;
    margin: 70px auto 0 auto;
    padding-bottom: 100px;
  }

  .root :global(.slick-prev) {
    bottom: 0;
    top: auto;
    transform: translate(120%, 120%) !important;
  }

  .root :global(.slick-next) {
    bottom: 0;
    top: auto;
    transform: rotate(180deg) translate(120%, -120%) !important;
  }
}
