.root {
  position: relative;
  padding: 60px 0;
  background-color: rgba(240, 243, 245, 0.3);
}

.root .leftSwirt {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}

.title {
  text-align: center;
  margin-bottom: 70px;
}

.stepsWrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  align-items: flex-start;
}

.stepsSpacer {
  position: absolute;
  width: 80%;
  border-top: 1px dashed #000;
  top: 36px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.stepWrapper {
  display: grid;
  grid-gap: 20px;
  text-align: center;
  padding: 0 15px;
}

.stepNumber {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  color: var(--primary-color);
  margin-bottom: 0;

  width: 71px;
  height: 71px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid var(--primary-color);
  border-radius: 50%;
  background-color: var(--white);

  align-self: center;
  justify-self: center;
  z-index: 2;
}

.graphicWrapper {
  height: 234px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepTitle {
  margin-bottom: 0;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 25px;
  margin-top: 55px;
  padding: 0 15px;
}

.buttonWrapper > button {
  max-width: 387px;
  border-radius: 10px;
}

.buttonLink,
.buttonLink:hover {
  color: var(--white);
}

.textUnderButton > a {
  color: var(--secondary-color);
}

@media (max-width: 515px) {
  .title {
    font-size: 10vw;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .stepsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .stepsWrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .stepsSpacer {
    display: block;
  }

  .stepWrapper {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1400px) {
  .root .leftSwirt {
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .root .leftSwirt {
    display: none;
  }
}
