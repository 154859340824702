.root {
  min-width: 264px;
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #ffffff;
}
.ImageBackgroundWrapper {
  position: relative;
  background-color: var(--disabled-gray);
  height: 170px;
}
.root .ImageBackgroundWrapper > img {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
}
.badgesWrapper {
  padding: 10px 12px 0 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.blogTextWrapper,
.offerTextWrapper {
  padding: 30px;
}

.blogTextWrapper h5 {
  font-weight: 600;
  color: #0a1e5a;
  margin-bottom: 20px;
}

.blogTextWrapper a {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1098f7;
}

.readMoreWrapper {
  margin-top: 20px;
}

.readMoreWrapper Img {
  margin-left: 8px;
}

.blogTextWrapper h6 {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
}

.blogTextWrapper p {
  margin: 0;
}
.badge {
  padding: 5px 10px;
  background-color: var(--secondary-color);
  border-radius: 5px;
}
.badge img {
  margin-right: 5px;
  margin-bottom: 2px;
}
.price {
  display: flex;
  margin-top: 10px;
}
.offerTextWrapper img {
  margin: 0 0 2px 8px;
}
.offerTextWrapper p {
  margin-bottom: 5px;
}
.offerTextWrapper p img {
  margin: 0 10px 4px 0;
}
.price p {
  margin-left: 10px;
  margin-bottom: 0px;
}
.price h6 {
  margin-bottom: 0;
}
