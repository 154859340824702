.root {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.priceInterval h4 {
  color: #000000;
  font-weight: 600;
  font-size: 32px;
  line-height: 46px;
}

.priceInterval {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 5px;
}

.priceSticker {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
}

.numberInput {
  width: 50px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dadada;
  text-align: center;
  display: flex;
  border-radius: 8px;
}

.numberInput input[type="number"] {
  -moz-appearance: textfield;
}

.sliderWrapper {
  gap: 10px;
  display: flex;
  flex-direction: row;
}

.slider {
  height: 5px;
  border-radius: 5px;
  background: #dadada;
  position: relative;
  width: 100%;
  align-self: center;
}

.slider .progress {
  height: 5px;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #1098f7;
}

.rangeInput {
  position: relative;
}

.rangeInput input {
  position: absolute;
  height: 5px;
  width: 100%;
  top: -4px;
  -webkit-appearance: none;
  background: none;
  pointer-events: none;
}

.sliderInputWrapper {
  width: 100%;
  align-self: center;
}
