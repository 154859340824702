.wrapper {
  width: 335px;
  min-height: 540px;
  background: var(--primary-color-very-light);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 50px 20px;
  margin: 50px auto;
}

.wrapper p {
  margin: 20px 0;
  text-align: center;
}

.image {
  display: block;
  margin: 50px auto 0;
}
