.root {
  padding-top: 50px;
  padding-bottom: 50px;
}

.textTitle {
  margin-bottom: 25px;
}

.root .buttonsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  grid-gap: 10px;
}

.root :global(.btn) {
  width: 220px;
  background-color: #eff1f2;
  border-color: #eff1f2;
  color: #000;
}

.root :global(.btn):hover {
  background-color: #e7e7e7;
  border-color: #e7e7e7;
  animation: var(--animation);
}

.root :global(.btn):focus {
  box-shadow: none;
}

.modal > div {
  width: 90%;
  max-width: 1132px;
}

.modal > div > div {
  background: var(--white);
  border: 1px solid #ccc;
  box-shadow: 0px 5px 30px rgba(41, 42, 44, 0.15);
  border-radius: 20px;
  padding: 15px 35px;
}

.modal > div > div > div:first-child {
  border: none;
}

.modal :global(.modal-header) span {
  font-size: 30px;
  font-weight: 300;
  color: var(--primary-color);
}

@media (min-width: 992px) {
  .textTitle {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .modal > div {
    width: 70%;
    max-width: 1132px;
  }
}
