.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
  flex-wrap: wrap;
}

.titleWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.title,
.value {
  font-size: 12px;
  line-height: 20px;
  color: var(--black);
  margin-bottom: 0;
}
.title {
  word-break: break-all;
}
.value {
  font-weight: 600;
}
