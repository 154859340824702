.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 19px;
  justify-content: center;
  margin-bottom: 20px;
}
.root h4 {
  margin-bottom: 40px;
}
.root {
  margin: 50px auto;
}
.root h6 {
  margin-top: 40px;
  cursor: pointer;
}

.rotated {
  margin-left: 10px;
}
.rotated img {
  transform: scaleY(-1);
}
