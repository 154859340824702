.formLabel {
  font-weight: 600;
  color: var(--black);
}

.bigFormLabel {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 20px;
}

.formInputWithCheckbox {
  display: block;
}

.formInput,
.formPhoneInput > input {
  min-height: 48px;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 13px 20px;
}

.formInput::placeholder {
  font-size: 15px;
  color: #a5aab4;
}

.checkboxText {
  font-weight: 400;
  color: var(--black);
  margin-bottom: 15px;
}

.button button {
  max-width: 297px;
  margin-top: 40px;
}

.formColumnFlex {
  display: grid;
  grid-template-columns: 1fr;
}

.datesWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.datesWrapper > input {
  height: 48px;
  max-width: 200px;
  border: 1px solid #dadada;
  border-radius: 8px;
  padding: 13px 20px;
  flex-direction: row-reverse;
  column-gap: 10px;
}

.radiosWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

.rootRadioForms {
  position: relative;
  min-height: 29px;
}

.radioFormsInput {
  visibility: hidden;
  transition: var(--animation);
}

.radioFormsInput:disabled + .radioFormsButton {
  opacity: 0.5;
}

.rootRadioForms .radioFormsButton {
  position: absolute;
  left: 0;
  top: 0;
  width: max-content;
  min-height: 29px;
  padding: 0 12px;
  background: var(--secondary-background-color);
  border-radius: 19px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.rootRadioForms .radioFormsButton {
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  color: var(--primary-color-light);
}

.rootRadioForms .radioFormsButtonClose {
  font-size: 14px;
  line-height: 9px;
  margin-left: 6px;
}

.radioFormsInput:checked ~ .radioFormsButton {
  background: var(--primary-color-light);
  color: var(--white);
}

.modalWrapper {
  column-gap: 120px;
  row-gap: 50px;
}

.modal > div {
  width: 90%;
  max-width: 1132px;
  margin: 15px auto;
}

.modal > div > div {
  background: var(--white);
  border: 1px solid #ccc;
  box-shadow: 0px 5px 30px rgba(41, 42, 44, 0.15);
  border-radius: 20px;
  padding: 15px 35px;
}

.modal > div > div > div:first-child {
  border: none;
}

.modal > div > div > div:first-child > button {
  color: var(--primary-color);
  opacity: 1;
  font-size: 40px;
  font-weight: 400;
  padding-bottom: 0;
}

.modalTitle,
.contactLinkWrapper,
.contactText {
  margin-bottom: 30px;
}

.boldTextUnderTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 30px;
  color: var(--primary-color);
}

.normalText {
  margin-bottom: 50px;
}

.contactLink {
  font-size: 32px;
  font-weight: 600;
  line-height: 46px;
  color: var(--black);
  display: block;
}

.estimateTitleWrapper,
.findCourseTitleWrapper {
  margin-bottom: 50px;
}

.estimateTitleWrapper > h3,
.findCourseTitleWrapper > h3 {
  margin-bottom: 20px;
}

.estimateWrapper,
.estimateCheckboxWrapper,
.findCourseWrapper {
  margin-bottom: 50px;
}

.rangeWrapper {
  display: grid;
  grid-template-columns: 1fr 48px;
  column-gap: 20px;
  max-width: 470px;
}

.rangeValueBox,
.plusMinusWrapper > button {
  width: 48px;
  height: 48px;
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
}

.plusMinusWrapper {
  display: flex;
  column-gap: 10px;
}

.plusMinusWrapper > button {
  font-size: 22px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white);
  transition: 0.3s;
}

.plusMinusWrapper > button:disabled {
  opacity: 0.5;
}

.plusMinusWrapper > button:disabled {
  opacity: 0.5;
}

.plusMinusWrapper > input:disabled {
  background-color: var(--white);
}

.estimateCheckboxWrapper,
.findCourseCheckboxWrapper {
  max-height: 115px;
  overflow: hidden;
  overflow-y: scroll;
}

.estimateCheckboxWrapperFullHeight {
  max-height: max-content;
  overflow: visible;
}

.estimateCheckboxWrapper::-webkit-scrollbar,
.findCourseCheckboxWrapper::-webkit-scrollbar {
  width: 5px;
}

.estimateCheckboxWrapper::-webkit-scrollbar-track,
.findCourseCheckboxWrapper::-webkit-scrollbar-track {
  background: #dadada;
  border-radius: 100px;
}

.estimateCheckboxWrapper::-webkit-scrollbar-thumb,
.findCourseCheckboxWrapper::-webkit-scrollbar-thumb {
  background: #1098f7;
  border-radius: 100px;
}

.estimateCheckboxWrapper::-webkit-scrollbar-thumb:hover,
.findCourseCheckboxWrapper::-webkit-scrollbar-thumb:hover {
  background: #1098f7;
  border-radius: 100px;
}

.estimateCheckboxWrapper > label,
.findCourseCheckboxWrapper > label {
  display: flex;
  align-items: center;
}

.estimateCheckboxWrapper > label > input,
.findCourseCheckboxWrapper > label > input {
  margin-right: 13px;
}

.switchWrapperWithValue {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.switchWrapperWithValue > span {
  margin-bottom: 0;
}

.customSwitchWrapper {
  position: relative;
  display: block;
  width: 25px;
  height: 12.5px;
  margin-bottom: 0;
}

.customSwitchWrapper .customSwitch {
  opacity: 0;
  width: 0;
  height: 0;
}

.customSwitchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.customSwitchSlider::before {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

.customSwitch:checked ~ .customSwitchSlider {
  background-color: #2196f3;
}

.customSwitch:focus ~ .customSwitchSlider {
  box-shadow: 0 0 1px #2196f3;
}

.customSwitch:checked ~ .customSwitchSlider::before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

.fileUploaderWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.fileUploaderWrapper > span {
  color: #5f6267;
}

.fileUploaderWrapper > div > button {
  width: 163px;
  min-width: auto;
  font-weight: 500;
  color: var(--secondary-dark-blue-color);
  padding: 14px 20px;
  background: var(--secondary-background-color);
  border-radius: 10px;
  column-gap: 3px;
  line-height: 14px;
}

.rootSwitchForms {
  position: relative;
  display: flex;
  align-items: center;
  width: 25px;
  height: 12.5px;
  margin-bottom: 0;
}

.switchFormsInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchInputSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.switchInputSlider::before {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.switchFormsInput:checked + .switchInputSlider {
  background-color: var(--secondary-color);
}

.switchFormsInput:focus + .switchInputSlider {
  box-shadow: 0 0 1px var(--secondary-color);
}

.switchFormsInput:checked + .switchInputSlider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
.switchFormsLabel {
  position: relative;
  left: 25px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-left: 15px;
  margin-bottom: 0;
}

.rootYourNeedsForm {
  padding: 50px;
  border: 1px solid var(--divider-color);
  border-radius: 10px;
}

.rootYourNeedsForm .estimateWrapper {
  margin-bottom: 25px;
}

.rootYourNeedsForm .button button {
  margin-top: 10px;
}

.additionalInfoGroup {
  margin-top: 10px;
}

.additionalInfoGroup > a {
  color: var(--secondary-color);
}

.formPhoneInput {
  position: relative;
}

.formPhoneInput > input {
  padding-left: 60px;
}

.formPhoneInput > input:focus {
  outline: none;
}

.formPhoneInput > div {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.examTrainingCourseSelectWrapper {
  display: flex;
  column-gap: 50px;
}

@media (max-width: 480px) {
  .modal > div > div {
    padding: 15px 20px;
  }

  .datesWrapper {
    display: block;
  }

  .rootYourNeedsForm {
    padding: 30px 30px 15px;
  }
}

@media (max-width: 515px) {
  .modalTitle {
    font-size: 8vw;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  .formColumnFlex {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
  }
}

@media (min-width: 1200px) {
  .modal > div {
    max-width: 1132px;
  }
}

@media (min-width: 1600px) {
  .formPhoneInput > input {
    width: 200px;
  }

  .modal > div {
    width: 70%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .formColumnFlex {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

@media (min-width: 1300px) and (max-width: 1600px) {
  .formColumnFlex {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}
