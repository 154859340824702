.wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  max-width: 1200px;
  margin: 40px auto 50px;
}
.textWrapper h4 {
  text-align: left;
  max-width: 400px;
}
@media (max-width: 786px) {
  .wrapper {
    padding-top: 48.62%;
    position: relative;
    width: 90%;
    overflow: hidden;
  }
  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
  }
}
