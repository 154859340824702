.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  align-items: center;
  padding: 50px 30px;
  margin: 60px auto;
  border-radius: 8px;
  border: 1px solid var(--divider-color);
}

.headerColumn {
  max-width: 80%;
}

.headerColumn h3 {
  font-size: 42px;
  font-weight: 700;
  line-height: 54px;
  color: var(--primary-color);
  margin-bottom: 20px;
}

.headerColumn p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0;
}

.linkColumn {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.linkColumn > div > div {
  justify-content: flex-start;
}

@media (max-width: 767px) {
  .linkColumn > div:not(:last-of-type) > div {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) {
  .linkColumn {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

@media (min-width: 992px) {
  .container {
    grid-template-columns: 1fr 1.5fr;
  }
}
