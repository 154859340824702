.wrapper {
  margin: 50px auto;
}

.title {
  text-align: center;
}

.mainImage {
  display: block;
  margin: 0 auto;
}

.wrapper h4 {
  margin-top: 50px;
  margin-bottom: 40px;
}

.slider {
  margin-bottom: 40px;
}

.wrapper :global(.slick-slide > div) {
  width: 100%;
  border: 20px solid transparent;
}

.wrapper :global(.slick-dots > li > button) {
  width: 13px;
  height: 13px;
  border: solid 1px var(--primary-color-light);
  background-color: var(--primary-color-light);
  border-radius: 50%;
  color: transparent;
}

.wrapper :global(.slick-arrow) {
  margin: 0 35px 0 25px;
  z-index: 10;
  position: absolute;
  top: 34%;
}

.wrapper :global(.slick-arrow::before) {
  color: var(--secondary-color);
  font-size: 32px;
}

.wrapper :global(.slick-prev::before) {
  color: var(--disabled-gray);
}

.wrapper :global(.slick-dots > li > button::before) {
  display: none;
}

.wrapper :global(li.slick-active > button) {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

@media (max-width: 1024px) {
  .wrapper :global(.slick-slide) {
    width: 100%;
  }
}

@media (max-width: 515px) {
  .title {
    font-size: 42px;
    line-height: 1.2;
  }
}
