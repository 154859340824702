.root {
  padding-top: 70px;
  padding-bottom: 70px;
}

.rowGap {
  column-gap: 120px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title,
.text {
  margin-bottom: 20px;
}

.contactLink {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: var(--secondary-color);
  margin-bottom: 30px;
}

.lastImage {
  width: max-content;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .lastImage {
    margin: 0 auto 50px;
  }

  .contactLink {
    margin: 0 auto 30px;
  }
}

@media (max-width: 992px) {
  .leftColumn {
    text-align: center;
  }
}

@media (max-width: 515px) {
  .title {
    font-size: 10vw;
    line-height: 1.2;
    word-break: break-word;
  }
}
