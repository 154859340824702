.root {
  min-height: 190px;
  margin-bottom: 30px;
}

.rootLink {
  display: grid;
  grid-template-columns: auto 60px;
  grid-gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 35px 35px 25px;
  border: 1px solid var(--divider-color);
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: var(--white);
}

.iconWrapper {
  margin-bottom: 10px;
}

.root .title {
  color: var(--black);
}

.text {
  margin-bottom: 0;
}

.arrowButton {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-background-color);
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.rootLink:hover .arrowButton img {
  animation: arrowSlide 1s;
}

@keyframes arrowSlide {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(150%);
  }
  26% {
    opacity: 0;
  }

  50% {
    transform: translateX(-150%);
  }
  51% {
    opacity: 0;
  }

  100% {
    transform: translateX(0);
  }
}
