.root {
  padding-top: 30px;
  padding-bottom: 30px;
}

.courseDetailsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 30px;
  border-bottom: 1px solid #dfe6ef;
}

.rightColumn {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
}

@media (max-width: 991px) {
  .rightColumn {
    border-top: 1px solid #dfe6ef;
    margin-top: 30px;
    padding-top: 30px;
  }
}

@media (min-width: 768px) {
  .root {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .courseDetailsWrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 120px;
  }

  .courseDetailsWrapper > div:first-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}

@media (min-width: 992px) {
  .courseDetailsWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
