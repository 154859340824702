.wrapper p {
  border-radius: 10px;
  padding: 20px 30px 20px 70px;
  position: relative;
}

.wrapper p::before {
  content: url("../assets/primaryQuote.svg");
  position: relative;
  position: absolute;
  top: 4px;
  left: 10px;
  color: var(--primary-color);
  font-size: 133px;
}

.wrapper img {
  width: 51px;
  height: 51px;
  border-radius: 50%;
  margin-right: 20px;
}

.userData {
  display: flex;
  margin: 40px auto;
}

.userName {
  color: var(--primary-color);
  margin: 0px;
  text-align: left;
}

.userTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

@media (max-width: 1024px) {
  .userData {
    display: flex;
    margin: 0 auto;
  }
}
