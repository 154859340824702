.root {
  padding: 70px 0;
}

.title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: -0.02em;
}

.partnersWrapper {
  display: flex;
  /* grid-template-columns: repeat(6, 1fr); */
}

.partnersWrapper > div {
  margin: auto;
}

@media (max-width: 768px) {
  .partnersWrapper {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
  .partnersWrapper > div img {
    width: 100%;
  }
}
