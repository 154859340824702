.root,
.workshopRoot {
  padding: 30px 15px 25px;
}

.workshopWithBorder {
  border-bottom: 1px solid #dfe6ef;
  padding-bottom: 50px;
}

.contentWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  align-items: center;
}

.title,
.workshopTitle {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 46px;
}

.ctaWrapper {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 45px;
}

.buttonWrapper {
  min-width: 260px;
}

.priceWrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.priceLabel,
.price,
.text {
  margin-bottom: 0;
}

.price,
.ctaWorkshopSoonestLabel {
  color: var(--black);
}

.text {
  font-size: 12px;
  color: #007a5a;
}

@media (max-width: 767px) {
  .title,
  .workshopTitle {
    font-size: 32px;
    line-height: 46px;
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .graphicsWrapper {
    text-align: center;
  }
}

@media (min-width: 769px) {
  .contentWrapper {
    grid-template-columns: 2fr 1fr;
    grid-gap: 80px;
  }
}

@media (min-width: 992px) {
  .title {
    max-width: 80%;
    margin-bottom: 30px;
  }

  .ctaWrapper {
    flex-direction: row;
    align-items: center;
    column-gap: 30px;
  }
}
