.root {
  position: relative;
  padding: 60px 0;
  display: flex;
  min-height: 925px;
}

.root .leftSwirt {
  position: absolute;
  left: 0;
  transform: translateY(50px);
}

.root .leftCircle {
  position: absolute;
  left: 0;
  top: 40%;
}

.root .rightSwirt {
  position: absolute;
  right: 0;
  top: 70%;
}

.coursesContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  gap: 31px;
}

.eventCardColumn {
  height: 100%;
  max-width: 380px;
}

.eventCardColumn > div {
  height: 100%;
}

.root :global(.slick-next)::before,
.root :global(.slick-prev)::before {
  display: none;
}

.root :global(.slick-next),
.root :global(.slick-prev) {
  z-index: 1;
}

.root :global(.slick-arrow) {
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--secondary-background-color);
}

.root .loadMoreButton {
  min-width: 220px;
  padding: 12px 25px;
  border: none;
  align-self: center;
  border-radius: 15px;
  color: var(--secondary-dark-blue-color);
  border-color: var(--secondary-background-color);
  background-color: var(--secondary-background-color);
}

.emptyListText {
  border: 1px solid var(--divider-color);
  border-radius: 10px;
  padding: 20px;
  text-align: center;

  font-size: 18px;
  font-weight: 500;
  color: var(--primary-color);
}

.emptyListText.isCoursesForYou {
  margin: 0 auto;
}

@media (min-width: 992px) {
  .root :global(.slick-prev) {
    transform: translate(100%, -50%);
    left: 0;
  }

  .root :global(.slick-next) {
    transform: rotate(180deg) translate(100%, 50%) !important;
    right: 0;
  }

  .root :global(.slick-slide) {
    opacity: 0.5;
  }
}

@media (min-width: 992px) {
  .root.isCoursesForYou {
    padding-top: 0;
  }

  .root.isCoursesForYou .mainRow {
    display: grid;
    grid-template-columns: 1fr;
  }

  .root.isCoursesForYou .coursesRow {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .coursesRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0;
    width: 760px;
  }

  .eventCardColumn {
    height: auto;
  }
}

@media (max-width: 1199px) {
  .mainRow {
    display: grid;
    grid-template-columns: 375px 1fr;
  }

  .coursesRow {
    display: grid;
    grid-gap: 25px;
    width: 100%;
  }

  .eventCardColumn,
  .eventCardColumn > div > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .root :global(.slick-prev) {
    bottom: 0;
    top: auto;
    transform: translate(120%, 120%) !important;
  }

  .root :global(.slick-next) {
    bottom: 0;
    top: auto;
    transform: rotate(180deg) translate(120%, -120%) !important;
  }

  .mainRow {
    grid-template-columns: 300px 1fr;
    grid-gap: 20px;
  }
}

@media (max-width: 767px) {
  .root {
    padding: 0;
    min-height: 820px;
  }
  .root .leftSwirt,
  .root .leftCircle,
  .root .rightSwirt {
    display: none;
  }
  .eventCardColumn {
    max-width: 380px;
  }
  .coursesContainer {
    align-items: center;
    margin: 15px auto;
    padding: 0;
  }
  .filterCoursesWrapper {
    align-self: flex-start;
    color: #1098f7;
    margin: 30px 15px 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;
  }
  .filterCoursesWrapper img {
    margin: 0 8px;
  }
  .switchFilterButton {
    background: none;
    border: 0;
    color: #1098f7;
    align-items: center;
    display: flex;
    border-color: var(--secondary-background-color);
    background-color: var(--secondary-background-color);
    padding: 12px 25px;
    border-radius: 15px;
  }
  .closeFilterButton {
    margin-top: 31px;
    background: none;
    border: 0;
    color: #1098f7;
    align-items: center;
    display: flex;
  }
  .closeFilterButton img {
    margin: 0 8px;
  }
  .mainRow {
    display: block;
    padding: 30px 0 100px;
  }

  .mainRow > div:first-of-type {
    margin-bottom: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .root .leftSwirt {
    transform: translate(-50%, 50px);
  }
}
