.title {
  margin-bottom: 30px;
}

.SingleInformation {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 140px 1fr;
  column-gap: 10px;
  margin-bottom: 20px;
}

.SingleInformation:last-of-type {
  margin-bottom: 0;
}

.labelWrapper {
  display: flex;
  align-items: center;
  column-gap: 6px;
}

.answerWrapperTooltip {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 5px;
}

.answerWrapperTooltip > img {
  border-left: 2px solid #a5aab4;
  padding-left: 5px;
  margin-left: 5px;
}

.label,
.answer,
.notSure,
.contactUs {
  margin-bottom: 0;
}

.label {
  font-size: 15px;
  font-weight: 600;
}

.notSure,
.contactUs {
  font-size: 12px;
  line-height: 1;
}

.notSure {
  color: #5f6267;
}

.contactUs {
  color: var(--secondary-color);
  cursor: pointer;
}

.contactUs:hover {
  text-decoration: none;
}

.linkWrapper {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .SingleInformation {
    grid-template-columns: 180px 1fr;
  }
}
