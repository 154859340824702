.root img {
  width: 100%;
  object-fit: cover;
}
@media (min-width: 992px) {
  .imageWrapper {
    height: 400px;
  }
}

@media (max-width: 786px) {
  .root {
    display: flex;
    overflow: hidden;
    justify-content: center;
  }
  .imageWrapper {
    height: 500px;
  }
}
