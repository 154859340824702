.root {
  padding: 70px 0;
  background-color: #f7fafd;
}

.title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 70px;
}

.advantagesWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.advantagesColumn {
  text-align: center;
  margin-bottom: 20px;
}

.advantagesColumn:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
}

.iconBackground {
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 30px;
}

.text {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  color: var(--primary-color);
  max-width: 90%;
  margin: 0 auto;
}

@media (max-width: 515px) {
  .iconBackground {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .advantagesWrapper {
    grid-template-columns: repeat(5, 1fr);
  }

  .advantagesColumn:nth-child(5) {
    grid-column-start: auto;
    grid-column-end: auto;
  }
}
