.root {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tagsWrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  max-width: 252px;
  flex-wrap: wrap;
}

.clearFiltersButton {
  display: flex;
  border: 0;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #b3b3b3;
  background-color: rgba(0, 0, 0, 0);
}

@media (min-width: 992px) {
  .root.isCoursesForYou {
    flex-direction: row;
  }

  .root.isCoursesForYou .tagsWrapper {
    max-width: 100%;
    width: 100%;
  }
}
