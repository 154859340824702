.title {
  margin-bottom: 30px;
}

.priceWrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  margin-bottom: 10px;
}

.priceTitle {
  line-height: 32px;
  margin-bottom: 0;
}

.price {
  color: var(--black);
  margin-bottom: 0;
}

.greenInfo {
  color: #007a5a;
  margin-bottom: 30px;
}

.boldTitle {
  font-weight: 600;
  letter-spacing: 0.01px;
}
