.tagWrapper {
  background: #f0f3f5;
  border-radius: 19.0909px;
  text-align: center;
  color: #0a1e5a;
  border: 0;
  padding: 8px 12px;
}

.selectedTagWrapper {
  background: #1e3278;
  border-radius: 19.0909px;
  text-align: center;
  color: #ffffff;
  border: 0;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
