.singleCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;

  width: 158px;
  height: 202px;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.singleCard img {
  border: 1px solid var(--disabled-gray);
  padding: 20px;
  border-radius: 50%;
}

.singleCard h6 {
  margin: 0;
}
.singleCard h6:hover {
  cursor: pointer;
  text-decoration: underline;
}
